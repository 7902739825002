$co-font-family-base: 'Helvetica Neue LT W05 55 Roman', Helvetica, Arial, sans-serif;
//$co-body-bg:        #fff;

$co-primary: #0673d4;
$co-secondary: #02ce8a;

$co-dark: #35353f;

$co-base-width-height: 60px;
$base-width: $co-base-width-height;
$base-height: $co-base-width-height;

//status
$co-status-gray: #53585f;
$co-status-green: #049d0b;
$co-status-blue: #0c64b3;
$co-status-purple: #8267be;

//borders
$co-border-dark: #000;
$co-border-light: #ededed;

//text
// $co-text-dark: $co-body-bg;
$co-body-color: #545454;

//buttons
$co-button-green: #20c875;
