@import 'co-variables';

// Configuration
@import '../../../node_modules/bootstrap/scss/functions';

// Colors:
// Grayscale and brand colors for use across Bootstrap.

$primary: $co-primary;
$success: #20c875;
$info: #17a2b8;
$warning: #ffc107;
$danger: #e2445c;
$secondary: #25252c80;
$gray-200: #f5f5f5;

// Options:
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Components:
// Define common padding and border radius sizes and more.

$border-radius: 0.25rem;
$border-radius-lg: 0.125rem;
$border-radius-sm: 0.1rem;

// Body:
// Settings for the `<body>` element.

$font-family-sans-serif: $co-font-family-base;

// Typography:
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;
$body-color: $co-body-color;

// gutter size
$grid-gutter-width: 1rem !default;

// removes minimum contrast ratio
$min-contrast-ratio: 0;

@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../../../node_modules/bootstrap/scss/root';
@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/type';
@import '../../../node_modules/bootstrap/scss/images';
@import '../../../node_modules/bootstrap/scss/containers';
@import '../../../node_modules/bootstrap/scss/grid';
@import '../../../node_modules/bootstrap/scss/tables';
@import '../../../node_modules/bootstrap/scss/forms';
@import '../../../node_modules/bootstrap/scss/buttons';
@import '../../../node_modules/bootstrap/scss/transitions';
@import '../../../node_modules/bootstrap/scss/dropdown';
//@import "bootstrap/scss/button-group";
@import '../../../node_modules/bootstrap/scss/nav';
@import '../../../node_modules/bootstrap/scss/navbar';
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
@import '../../../node_modules/bootstrap/scss/badge';
@import '../../../node_modules/bootstrap/scss/alert';
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/list-group";
@import '../../../node_modules/bootstrap/scss/close';
@import '../../../node_modules/bootstrap/scss/toasts';
@import '../../../node_modules/bootstrap/scss/modal';
@import '../../../node_modules/bootstrap/scss/tooltip';
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
@import 'bootstrap/scss/placeholders';

// Helpers
@import '../../../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../../../node_modules/bootstrap/scss/utilities/api';

.bg-secondary {
    opacity: 0.5 !important;
}

.btn-secondary {
    border: 1px solid transparent;

    &:hover {
        border: 1px solid transparent;
    }
}

.text-gray-500 {
    color: var(--bs-gray-500);
}
