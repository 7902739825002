@import 'co-variables';

.p-element,
.p-component,
.p-inputtext {
    font-family: $co-font-family-base;
    font-size: 0.875rem;
    color: $co-body-color;
}

.p-dialog-mask {
    z-index: 1500 !important;
}

// dialog
.p-dialog {
    border-radius: 8px;

    .p-dialog-header {
        border-bottom: none;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        color: $co-body-color;

        .p-dialog-title {
            font-weight: initial;
            font-size: 1.125rem;
        }
    }

    .p-dialog-content {
        color: $co-body-color;
    }

    .p-dialog-content:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .p-dialog-footer {
        border-top: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        button {
            margin: unset;
        }

        p-footer {
            display: flex;
            justify-content: flex-end;
        }
    }
}

// Remove close button icon
.hide-close.p-dialog .p-dialog-header .p-dialog-header-icons {
    display: none;
}

// tenant picker dialog
.tenant-dialog.p-dialog {
    background-color: #35353f;

    .p-dialog-header,
    .p-dialog-content {
        background-color: inherit;
    }
}

// dropdown
.p-dropdown {
    border-radius: 0;
    width: 100%;
    border: 1px solid #5454541a;
    height: calc(1.75rem + 2px);

    .p-dropdown-label {
        display: inline-flex;
        align-items: center;
    }

    &.p-disabled {
        opacity: 1;
        background: #5454540f 0 0 no-repeat padding-box !important;

        .p-dropdown-trigger {
            display: none;
        }
    }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #212529;
    background: #f5f5f5;
}

.p-dropdown:not(p.disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #ced4da;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding-left: 1rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #f5f5f5;
}

// checkbox
.p-checkbox {
    width: 14px;
    height: 14px;

    .p-checkbox-box {
        width: 14px;
        height: 14px;
        border-radius: 0;
        border: 1px solid #ced4da;
        transition: none;

        .p-checkbox-icon {
            font-size: 10px;
            transition-duration: 0s;
        }
    }
}

// radio button
.p-radiobutton .p-radiobutton-box {
    height: 14px;
    width: 14px;

    .p-radiobutton-icon {
        height: 8px;
        width: 8px;
    }
}

// spinner
.spinner-primary {
    .p-progress-spinner-circle {
        stroke: #0673d4;
        animation: default-spinner-dash 1.5s ease-in-out infinite;
    }
}

.spinner-white {
    .p-progress-spinner-circle {
        stroke: #ffffff;
        animation: default-spinner-dash 1.5s ease-in-out infinite;
    }
}

@keyframes default-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

// table
.p-datatable-table .p-datatable-thead > tr > th {
    padding: 0.25rem 1.5rem;
    color: $co-body-color;
}

.p-datatable-table .p-datatable-tbody > tr {
    color: $co-body-color;
}

//time validation table
.vt-time table tr th,
.vt-time table tr td {
    padding: 0.5rem 0 !important;
    vertical-align: middle;
}

.vt-time table tr th {
    font-size: 11px;
    text-transform: uppercase;
    border-bottom: none;
}

.vt-time {
    .p-datatable {
        .p-datatable-thead {
            > tr {
                > th {
                    background-color: #ffffff;
                    border: 1px solid #dee2e6;
                    border-left: none;
                    border-right: none;
                }
            }
        }

        .p-datatable-tbody {
            > tr {
                > td {
                    border: none;
                    border-bottom: 1px solid #dee2e6;
                }
            }
        }
    }
}

//work scheduler table
.schedule-table {
    overflow: auto;
    width: 100%;
    .p-datatable-table {
        .p-datatable-thead {
            > tr {
                > th {
                    padding: 0.25rem 1.5rem;
                }
            }
        }

        .p-datatable-tbody {
            > tr > td {
                border: 1px solid #5454541a;
            }
        }
    }
}

// progress bar
.p-progressbar {
    border-radius: 0;
    height: 10px;
    background-color: rgba(84, 84, 84, 0.15);

    .p-progressbar-value {
        background: #20c875;
    }
}

.date-picker.p-calendar .p-datepicker {
    width: unset !important;
}

// treeTable
.template-tree-table {
    color: #545454 !important;

    td {
        border: none !important;
        padding: 0 !important;
        height: 4.8125rem;
    }
}

.p-table-overwrite table {
    border-collapse: separate;

    th {
        background: #ebebeb !important;
        font-weight: 400 !important;
        border-color: #d5d5d5 !important;
        border-width: 1px 0 !important;
        height: 2rem;
    }

    td {
        height: 50px;
        border-color: #e7e7e7 !important;
        border-width: 0 0 1px 0 !important;
    }
}
.p-table-overwrite-rtl table {
    border-collapse: separate;
    text-align: right !important;
    th {
        background: #ebebeb !important;
        font-weight: 400 !important;
        border-color: #d5d5d5 !important;
        border-width: 1px 0 !important;
        height: 2rem;
    }

    td {
        height: 50px;
        border-color: #e7e7e7 !important;
        border-width: 0 0 1px 0 !important;
    }
}

// inputtext
.p-inputtext {
    border-radius: 0;
    padding: 0.5rem;
}

// fix issue with header in responsive TreeTable
.flex-tree .p-treetable-scrollable-header {
    flex-shrink: 0;
}

// hide scroll when not needed on responsive TreeTable
.flex-tree .p-treetable-scrollable-body {
    overflow: auto !important;
}

ul.p-dropdown-items {
    padding-left: 0;
    padding-right: 0;
}
